<template>
	<div class="dashboard">
		<AppTopbar
		title=""
		buttonText="New Order"
		buttonLink="/new"
		buttonIconClass="icon-add"
		></AppTopbar>
	<div class="container-fluid pt-100 text-center">
		<div v-if="!mobile" style="position:relative;display: inline-flex;width:100%" class="ml-134">
			<img src="@/assets/images/unauthorized-person.png" class="unauthorized-person">
			<div class="text-box">
				<span style="font-size: 35px;font-weight: bold">Access Denied</span>
				<p style="margin: 15px 0">
					<span v-if="user.sellerStatus == 1">Your role does not allow you to access this page</span>
					<span v-else>This feature is for Activated Profiles only</span>
				</p>
				<b-button to="/dashboard" variant="secondary" size="sm" class="ml-auto w-sm-100 mt-20 mt-md-0">
					Go Home <i class="icon-arrow fs10 ml-7"></i>
				</b-button>
			</div>
		</div>
		<div v-else>
			<img src="@/assets/images/unauthorized-person.png" class="unauthorized-person-mobile">
			<div>
				<span style="font-size: 20px;font-weight: bold">Access Denied</span>
				<p style="margin: 10px 0">
					<span v-if="user.sellerStatus == 1">Your role does not allow you to access this page</span>
					<span v-else>This feature is for Activated Profiles only</span>
				</p>
				<b-button to="/dashboard" variant="secondary" size="sm" class="ml-auto w-sm-100 mt-20 mt-md-0">
					Go Home <i class="icon-arrow fs10 ml-7"></i>
				</b-button>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
import AppTopbar from "@/components/layout/AppTopbar.vue";
export default {
	name: "PageNotFound",
	components: {
		AppTopbar
	},
	data() {
		return {
			mobile: false,
			innerWidth: window.innerWidth,
		}
	},
	mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },

	methods: {  
        onResize() {
            this.innerWidth = window.innerWidth
        }
    },
    computed : {
    	user() {
    		return this.$store.getters.user
    	}
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
	watch: {
		innerWidth(newValue) {
			this.size = newValue
			if(newValue < 700) {
				this.mobile = true
			} else {
				this.mobile = false
			}
		}
	}
}

</script>

<style scoped>
.unauthorized-person {
    height: 30rem;
    width: 30rem;
}
.unauthorized-person-mobile {
    height: 20rem;
    width: 20rem;
}
.dashboard {
	background: #f1f5f9;
	height: 100%;
}
.text-box {
    position: absolute;
	color: #555;
    bottom: 45px;
	right: 0px;
    height: 113px;
    width: 60%;
	text-align: left;
}
img {max-width: 685px;}
@media max(width:600px) {
	
}
</style>