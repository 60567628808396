var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("AppTopbar", {
        attrs: {
          title: "",
          buttonText: "New Order",
          buttonLink: "/new",
          buttonIconClass: "icon-add"
        }
      }),
      _c("div", { staticClass: "container-fluid pt-100 text-center" }, [
        !_vm.mobile
          ? _c(
              "div",
              {
                staticClass: "ml-134",
                staticStyle: {
                  position: "relative",
                  display: "inline-flex",
                  width: "100%"
                }
              },
              [
                _c("img", {
                  staticClass: "unauthorized-person",
                  attrs: {
                    src: require("@/assets/images/unauthorized-person.png")
                  }
                }),
                _c(
                  "div",
                  { staticClass: "text-box" },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "35px",
                          "font-weight": "bold"
                        }
                      },
                      [_vm._v("Access Denied")]
                    ),
                    _c("p", { staticStyle: { margin: "15px 0" } }, [
                      _vm.user.sellerStatus == 1
                        ? _c("span", [
                            _vm._v(
                              "Your role does not allow you to access this page"
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              "This feature is for Activated Profiles only"
                            )
                          ])
                    ]),
                    _c(
                      "b-button",
                      {
                        staticClass: "ml-auto w-sm-100 mt-20 mt-md-0",
                        attrs: {
                          to: "/dashboard",
                          variant: "secondary",
                          size: "sm"
                        }
                      },
                      [
                        _vm._v(" Go Home "),
                        _c("i", { staticClass: "icon-arrow fs10 ml-7" })
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          : _c("div", [
              _c("img", {
                staticClass: "unauthorized-person-mobile",
                attrs: {
                  src: require("@/assets/images/unauthorized-person.png")
                }
              }),
              _c(
                "div",
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "20px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v("Access Denied")]
                  ),
                  _c("p", { staticStyle: { margin: "10px 0" } }, [
                    _vm.user.sellerStatus == 1
                      ? _c("span", [
                          _vm._v(
                            "Your role does not allow you to access this page"
                          )
                        ])
                      : _c("span", [
                          _vm._v("This feature is for Activated Profiles only")
                        ])
                  ]),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-auto w-sm-100 mt-20 mt-md-0",
                      attrs: {
                        to: "/dashboard",
                        variant: "secondary",
                        size: "sm"
                      }
                    },
                    [
                      _vm._v(" Go Home "),
                      _c("i", { staticClass: "icon-arrow fs10 ml-7" })
                    ]
                  )
                ],
                1
              )
            ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }